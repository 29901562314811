import React, { useState, useEffect } from "react";
import "../styles/whatsapp.css";
import { FaArrowCircleUp } from "react-icons/fa";

const Whatsapp = () => {

  // Scrool window icon

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200) {
      setVisible(true);
    } else if (scrolled <= 200) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
           in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="whatsapp-container">

<div>
            <div
              className="scrool-up-icon"
              style={{ display: visible ? "inline" : "none" }}
              onClick={scrollToTop}
            >
              <div className="scrool">
                <FaArrowCircleUp
                  style={{ fontSize: "40px", color: "#0550A5" }}
                />
              </div>
            </div>
          </div>

      <a href="https://wa.link/ys97v4" target="_blank" rel="noopener noreferrer">
        <img src="/Assets/whatsapp.png" alt="WhatsApp" className="whatsapp" />
        <span className="whatsapp-text">Let's chat</span>
      </a>
    </div>
  );
}

export default Whatsapp;
