import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Form } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import { FaIndianRupeeSign } from "react-icons/fa6";

const DatabaseList = () => {
  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Delete modal
  const [show2, setShow2] = useState();
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState();
  const handleClose3 = () => {
    setShow3(false);
    setPriorityNo();
  };
  const handleShow3 = () => setShow3(true);

  // integrating post method
  const [DatabaselistCatName, setDatabaselistCatName] = useState("");
  const [InstituteName, setInstituteName] = useState("");
  //  const [AcadmeicYear, setAcadmeicYear] = useState("");
  const [Records, setRecords] = useState("");
  const [Fields, setFields] = useState("");
  const [Price, setPrice] = useState("");

  const Adddatabaselist = async () => {
    try {
      if (!DatabaselistCatName) {
        return alert("Please Choose Category");
      }
      if (!InstituteName) {
        return alert("Please add Institute Name");
      }
      // if (!AcadmeicYear) {
      //   return alert("Please add Acadmeic Year");
      // }
      if (!Records) {
        return alert("Please add Records");
      }
      if (!Fields) {
        return alert("Please add Fields");
      }
      if (!Price) {
        return alert("Please add Price");
      }

      const config = {
        url: "admin/databaselist",
        method: "post",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          DatabaselistCatName: DatabaselistCatName,
          InstituteName: InstituteName,
          //  AcadmeicYear: AcadmeicYear,
          Records: Records,
          Fields: Fields,
          Price: Price,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAdddatabase();
        handleClose();
        setDatabaselistCatName(" ");
        setInstituteName("");
        //  setAcadmeicYear("");
        setRecords("");
        setFields("");
        setPrice("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [CategoryFilter, setCategoryFilter] = useState([]);
  const [Adddatabase, setAdddatabase] = useState([]);
  const getAdddatabase = async () => {
    try {
      let res = await axios.get(
        "https://students-databse.co.in/api/admin/getdatabaselist"
      );
      if (res.status === 200) {
        // Sort categories by _id in descending order
        const sortedCategories = res.data.getdatabaselist.sort((a, b) =>
          b._id.localeCompare(a._id)
        );
        setAdddatabase(sortedCategories);
        setNoChangeData(sortedCategories);
        setCategoryFilter(sortedCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const Deletedatabaselist = async () => {
    try {
      const config = {
        url: "admin/Deletedatabaselist/" + Data,
        method: "delete",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAdddatabase();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setDatabaselistCatName(item?.DatabaselistCatName);
    setInstituteName(item?.InstituteName);
    //  setAcadmeicYear(item?.AcadmeicYear);
    setRecords(item?.Records);
    setFields(item?.Fields);
    setPrice(item?.Price);
  };

  const Editdatabase = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "admin/editdatabaselist",
        method: "put",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          DatabaselistCatName: DatabaselistCatName,
          InstituteName: InstituteName,
          // AcadmeicYear: AcadmeicYear,
          Records: Records,
          Fields: Fields,
          Price: Price,
          id: Data1?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAdddatabase();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAdddatabase();
  }, []);

  // pagination
  const [viewAll, setViewAll] = useState(false);
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  // const records = Adddatabase.slice(firstIndex, lastIndex);
  const records = viewAll ? Adddatabase : Adddatabase.slice(firstIndex, lastIndex);
  const npages = Math.ceil(Adddatabase.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
    setViewAll(false);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  function toggleView() {
    setViewAll(!viewAll);  // Toggle between "View All" and "View Less"
    if (viewAll) {
      setCurrentpage(1);  // Reset to first page when going back to pagination
    }
  }
  // Search filter
  const [nochangedata, setNoChangeData] = useState([]);
  const [searchH, setSearchH] = useState("");

  const handleFilterH = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchH(searchTerm);
    if (searchTerm !== "") {
      const filteredData = nochangedata.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(searchTerm)
        )
      );
      setAdddatabase(filteredData);
    } else {
      setAdddatabase(nochangedata);
    }
  };
  const handleClearSearch = () => {
    setSearchH(""); // Clear the search input field
    setAdddatabase(nochangedata); // Reset the data to the original dataset
  };
  //integrating get method of category
  const [Addcategory, setAddcategory] = useState([]);
  const getAddcategory = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/admin/getcategory");
      if (res.status === 200) {
        setAddcategory(res.data.getcategory);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const [SelectedCat, setSelectedCat] = useState("");
  useEffect(() => {
    if (SelectedCat) {
      const catfilterdata = CategoryFilter?.filter(
        (ele) => ele?.DatabaselistCatName === SelectedCat
      );
      setAdddatabase(catfilterdata);
    } else {
      setAdddatabase(CategoryFilter);
    }
  }, [SelectedCat, CategoryFilter]);

  // Prioroty
  const [PriorityNo, setPriorityNo] = useState();
  const [Databaseid, setDatabaseid] = useState("");
  const Priority = async () => {
    if (!/^\d+$/.test(PriorityNo)) {
      return alert("Please enter numbers only");
    }
    try {
      const config = {
        url: "admin/addpriority",
        method: "put",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          id: Databaseid?._id,
          category: Databaseid?.DatabaselistCatName,
          priority: PriorityNo,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose3();
          getAdddatabase();
          setPriorityNo();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAddcategory();
  }, []);

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex gap-2 justify-content-center">
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <BsSearch />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  aria-describedby="basic-addon1"
                  onChange={handleFilterH}
                  value={searchH}
                />
              </div>
              <Button onClick={handleClearSearch}>Clear</Button>
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <div className="d-flex gap-2">
              <label>Category:</label>
              <Form.Select onChange={(e) => setSelectedCat(e.target.value)}>
                <option value="">Select Category</option>
                {Addcategory?.map((item) => {
                  return (
                    <option value={item?.CategoryName}>
                      {item?.CategoryName}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
          </div>
        </div>
        <div className="customerhead p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c">Database List</h2>
            <button className="admin-add-btn" onClick={handleShow}>
              Add Database List
            </button>
          </div>

          <div className="mb-3">
            <Table responsive bordered style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>SL.NO</th>
                  <th>Category Name</th>
                  <th>University Name</th>
                  <th>Records</th>
                  <th>Fields</th>
                  <th>Price</th>
                  <th>Priority</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {records
                  ?.sort((a, b) => {
                    if (a.priority === 0 && b.priority !== 0) return 1; // Move items with priority 0 to the end
                    if (b.priority === 0 && a.priority !== 0) return -1; // Move items with priority 0 to the end
                    return a.priority - b.priority; // Default sorting by ascending priority
                  })
                  ?.map((item, i) => {
                    return (
                      <tr>
                        <td style={{ paddingTop: "20px" }}>
                          {i + 1 + firstIndex}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.DatabaselistCatName}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.InstituteName}
                        </td>
                        {/* <td style={{ paddingTop: "20px" }}>{item.AcadmeicYear}</td> */}
                        <td style={{ paddingTop: "20px" }}>{new Intl.NumberFormat('en-IN').format(item?.Records)}</td>
                        <td style={{ paddingTop: "20px" }}>{item?.Fields}</td>
                        <td style={{ paddingTop: "20px" }}>
                          <FaIndianRupeeSign />{" "}
                          {new Intl.NumberFormat("en-IN").format(item?.Price)}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          <sapn>
                            <b>P : {item?.priority}</b>
                          </sapn>
                          <br />
                          <Button
                            onClick={() => {
                              handleShow3();
                              setDatabaseid(item);
                            }}
                          >
                            Add Priority
                          </Button>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className="text-success"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => handleShow1(item)}
                              />{" "}
                            </div>

                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => {
                                  handleShow2();
                                  setData(item?._id);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          <div>
          {!viewAll && (
            <nav>
              <ul className="pagination">
                <li className="not-allow">
                  <span>
                    <li className="next-prev">
                      <a
                        onClick={() => {
                          prevpage();
                        }}
                      >
                        &lt;
                      </a>{" "}
                    </li>
                  </span>
                </li>
                {numbers?.map((n, i) => {
                  return (
                    <li className="active-next" key={i}>
                      <a
                        href="#"
                        className="inactive"
                        onClick={() => changePage(n)}
                      >
                        {n}
                      </a>
                    </li>
                  );
                })}

                <li className="not-allow">
                  <span>
                    <li
                      className="next-prev"
                      onClick={() => {
                        nextpage();
                      }}
                    >
                      &gt;{" "}
                    </li>
                  </span>
                </li>                
              </ul>
            </nav>
          )}
            
            <Button onClick={toggleView}> {viewAll ? "View Less" : "View All"}</Button>
          </div>
          
        </div>

        {/* Add Package modal for Slider */}
        <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Database List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Select Category </label>
                <select
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setDatabaselistCatName(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {Addcategory?.map((item, i) => {
                    return (
                      <option value={item.CategoryName}>
                        {item.CategoryName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add University Name</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter University Name"
                  onChange={(e) => setInstituteName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Records</label>
                <input
                  type="number"
                  className="vi_0"
                  placeholder="Enter Records "
                  onChange={(e) => setRecords(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Fields</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Fields "
                  onChange={(e) => setFields(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Price</label>
                <input
                  type="number"
                  className="vi_0"
                  placeholder="Enter Price "
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={Adddatabaselist}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Home Slider
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Select Category </label>
                <select
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setDatabaselistCatName(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {Addcategory?.map((item, i) => {
                    return (
                      <option value={item.CategoryName}>
                        {item.CategoryName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add University Name</label>
                <input
                  type="text"
                  className="vi_0"
                  value={InstituteName}
                  onChange={(e) => setInstituteName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Records</label>
                <input
                  type="number"
                  className="vi_0"
                  value={Records}
                  onChange={(e) => setRecords(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Fields</label>
                <input
                  type="text"
                  className="vi_0"
                  value={Fields}
                  onChange={(e) => setFields(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Price</label>
                <input
                  type="number"
                  className="vi_0"
                  value={Price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose1}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn" onClick={Editdatabase}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose2}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={Deletedatabaselist}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Priority */}

        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Priority</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div
                style={{
                  border: "1px solid black",
                  padding: "10px",
                  display: "inline-block",
                }}
              >
                <strong>
                  <span style={{ color: "red" }}>*</span> Note:
                </strong>
                <span>
                  1 means top priority, while 2, 3, 4, and so on, indicate lower
                  priorities down to 10.
                </span>
              </div>
              <label>Priority Position :</label>
              <Form.Control
                type="text"
                placeholder="Enter the priority"
                onChange={(e) => setPriorityNo(e.target.value)}
                value={PriorityNo}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose3}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn" onClick={Priority}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default DatabaseList;
